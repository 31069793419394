import React from 'react'

type SizeProps = {
    width?: number
    height?: number
}

export default function useMobile () {
    const [size, setSize] = React.useState<SizeProps>({
        width: undefined,
        height: undefined
    })

    React.useEffect(() => {
        function handle(){
            setSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener("resize", handle)
        handle()

        return () => window.removeEventListener("resize", handle)
    }, [])

    const isMobile = size?.width ? size.width < 768 : false

    return {
        size,
        isMobile,
        ViewportClass: isMobile ? 'isMobile' : 'isDesktop'
    }
}