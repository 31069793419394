import React, { useContext, useEffect, useState } from 'react'

import {
    Grid,
    Input,
    Icon,
    Header,
    Button,
    Table,
    Popup,
    Pagination,
    Confirm
} from 'semantic-ui-react'

import './UserList.scss'

import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { logout, updateRoutes } from '../../Store/Actions'
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client'
import LoaderContext from '../../Library/Context/LoaderContext'
import useMobile from '../../Hooks/useMobile'
import TopBar from '../../Components/TopBar'

import { AdminMenu } from './AdminMenu'
import { ADMIN_GET_CATEGORY_LIST } from './connections'

type UserDataType = {
    id: number
    firstName: string
    lastName: string
    mail: string
    enabled: number
    UserGroups?: any
}

function useQueryParams() {
    const query = new URLSearchParams(useLocation().search)

    return {
        page: Number(query.get('page') || 1),
        search: query.get('search') || undefined
    }
}

export default function CategoryList() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { isMobile } = useMobile()
    const { token } = useSelector((state: any) => state)
    const { page = 1, search = '' } = useQueryParams();


    const [itemList, setItemList] = useState<any>([])
    const [query, setQuery] = useState<string>('')
    const [, setSearching] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<UserDataType>()
    const [form, setForm] = useState({
        search: ''
    })
    const [confirm, setConfirm] = useState<boolean>(false)
    
    const { setLoading } = useContext(LoaderContext)
    const { loading, data, refetch } = useQuery(ADMIN_GET_CATEGORY_LIST, {
        onError: (error) => {
            // @todo: Error sin permisos.
            if (error.message === 'Unauthenticated') {
                dispatch(logout(null))
                return history.push('/', {
                    error
                })
            }
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        },
        variables: {
            AdminCategoryListInput: {
                search: query
            }
        }
    })

    useEffect(() => {
        refetch()
    }, [page, search, refetch])

    useEffect(() => {
        setLoading(loading)
    }, [loading, setLoading])


    useEffect(() => {
        const searchQuery = query.trim()
        const params = new URLSearchParams()
        if (searchQuery) {
            params.append("search", searchQuery)
        } else {
            params.delete("search")
        }
        history.push({search: params.toString()})
    }, [query, history])

    useEffect(() => {
        if (!isMobile && !loading && data?.UserLearningRoutes?.LearningRoute) {
            let menuWidth = [].slice.call(document.getElementsByClassName('MenuItemElement')).reduce((acc, lr: any) => {
                /* console.log({
                    word: lr.innerHTML,
                    length: lr.innerHTML.length + 0,
                    width: lr.offsetWidth,
                    letterWidth: lr.offsetWidth / lr.innerHTML.length
                }) */
                return acc + lr.offsetWidth
            }, 0)
            if (menuWidth > window.innerWidth) {
                // ¿Cuantos caben?
                // Promedio por letra: 12px.
            }
            /* for (const elementt of ) {
                menuWidth += elementt.offsetWidth
            } */
        }
    }, [isMobile, data, loading])

    useEffect(() => {
        if (data?.adminGetCategoryList?.categories){
            const list = data?.adminGetCategoryList?.categories.reduce((acc: any, item: any) => {
                acc.push(item)
                return acc
            }, [])
            setItemList(list)
            // console.log(list)
            dispatch<any>(
                updateRoutes({
                    learningRoutes: list.map((item: any) => {
                        return {
                            id: item.id,
                            name: item.name
                        }
                    })
                })
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const updateForm = (e: any, { name, value }: any) => {
        const update = {...form}
        // @ts-ignore
        update[name] = value
        setForm(update)
    }

    return <Grid className="ViewCourses">
        <TopBar
            
            centerColumn={<Input icon placeholder='Escribe aquí para buscar una categoría...' fluid className="SearchBar" value={form.search} name="search" onChange={updateForm}>
            <input onKeyPress={(event) => {
                if (event.key === 'Enter') {
                    setQuery(form.search)
                    setSearching(true)
                }
            }} />
            <Icon name='search' />
        </Input>} />
        <Confirm
          open={confirm}
          content={`¿Estás seguro de reiniciar la contraseña para ${selectedUser?.mail}`}
          header={`Reinicio de contraseña para ${selectedUser?.lastName}, ${selectedUser?.firstName}`}
          onCancel={() => {
            setConfirm(false)
            setSelectedUser(undefined)
          }}
          onConfirm={() => {
            setConfirm(false)
            // doRecover({
            //     variables: {
            //         UserRecoverPasswdInput: {
            //             mail: selectedUser?.mail
            //         }
            //     }
            // })
          }}
        />
        <Grid.Row columns="equal">
            <AdminMenu />
            
            <Grid.Column>
                <Table celled collapsing style={{ width: '100%' }}>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nombre de Categoría</Table.HeaderCell>
                        <Table.HeaderCell>Cápsulas</Table.HeaderCell>
                        <Table.HeaderCell>Herramientas</Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {itemList?.map((item: any) => {
                            
                        return <Table.Row>
                            <Table.Cell>
                            <Header as='h4' image  style={{
                                opacity: item.total > 0 ? 1 : 0.65
                            }}>
                                {/* <Label color='red' floating circular empty /> */}
                                 <Icon name={item.icon} />
                                <Header.Content>
                                {item.name}
                                <Header.Subheader>
                                    {item.description}
                                </Header.Subheader>
                                </Header.Content>
                            </Header>
                            </Table.Cell>
                            <Table.Cell>
                                {item.total} <Popup
                                        trigger={<Icon name='info circle' color='grey' />}
                                        content={`Identificador único: ${item.id}`}
                                        inverted
                                        size='tiny'
                                        />
                            </Table.Cell>
                            <Table.Cell>
                                <Button.Group basic size='tiny'>
                                    <Button labelPosition='left' icon='edit' content='Editar' onClick={() => {
                                        history.push(`/admin/courses/edit-category/${item.id}`)
                                    }} />
                                    {/* <Button labelPosition='left' icon='right chevron' content='Forward' /> */}
                                </Button.Group>
                            </Table.Cell>
                        </Table.Row>})}
                    </Table.Body>
                </Table>
            </Grid.Column>
        </Grid.Row>
        {data?.adminGetUserList?.pagination?.last > 1 && <Grid.Row>
            <Pagination
                defaultActivePage={data?.adminGetUserList?.pagination.current}
                totalPages={data?.adminGetUserList?.pagination.last}
                ellipsisItem={data?.adminGetUserList?.pagination.last > 5 ? undefined : null}
                onPageChange={(event, pagedata) => {
                    // console.log(pagedata.activePage)
                    setLoading(true)
                    history.push({
                        pathname: '/admin/courses/list-category',
                        search: `?page=${pagedata.activePage}&search=${search}`
                    })
                }}
                />
        </Grid.Row>}
    </Grid>
}