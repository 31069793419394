import { gql } from '@apollo/client'

export const ADMIN_GET_CATEGORY_LIST = gql`
query adminGetCategoryList($AdminCategoryListInput: AdminCategoryListInput!) {
  adminGetCategoryList(input: $AdminCategoryListInput) {
    pagination {
      current
      last
      records
    }
    categories {
      id
      name
      description
      total
      icon
      sortOrder
      showToTrainers
      showToInterna
    }
  }
}
`

export const ADMIN_GET_USER_LIST = gql`
query adminGetUserList($AdminUserListInput: AdminUserListInput!) {
  adminGetUserList(input: $AdminUserListInput) {
    pagination {
      current
      last
      records
    }
    users {
      id
      firstName
      lastName
      mail
      enabled
      UserGroups {
        Group {
          name
          hasRoot
        }
      }
    }
  }
}
`

export const ADMIN_CREATE_CATEGORY = gql`
mutation adminCreateCategory($AdminCreateCategoryInput: AdminCreateCategoryInput!) {
    adminCreateCategory (input: $AdminCreateCategoryInput) {
        success
        learningRoutes {
          id
          name
        }
    }
}`

export const ADMIN_CREATE_USER = gql`
mutation adminCreateUser($AdminUserCreateInput: AdminUserCreateInput!) {
    adminCreateUser (input: $AdminUserCreateInput) {
        success
        password
    }
}`

export const ADMIN_CREATE_PILL = gql`
mutation adminCreatePill($AdminCreatePillInput: AdminCreatePillInput!) {
  adminCreatePill(input: $AdminCreatePillInput) {
    success
  }
}`
