import jwt_decode from 'jwt-decode'
import { NoAvatarImage } from '../../Assets'

export function updateRoutes({ learningRoutes }: any){
    return (dispatch: any) => {
        dispatch({ 
            type: 'SET_LEARNING_ROUTES',
            payload: {
                learningRoutes
            }
        })
    }
}

export function attemptLogin({ token, GroupInfo }: any){
    const decodedToken: any = jwt_decode(token)
    const isLogged = true

    decodedToken.user.fullName = `${decodedToken.user.firstName} ${decodedToken.user.lastName}`
    decodedToken.user.avatar = decodedToken.user.avatar === null ? NoAvatarImage : decodedToken.user.avatar

    return (dispatch: any) => {
            dispatch({ 
                type: 'LOGIN_USER',
                payload: {
                    token,
                    GroupInfo,
                    userInfo: decodedToken.user,
                    isLogged
                }
            })
            return isLogged
    }
}

export function logout(payload: any){
    return { type: 'LOGOUT', payload }
}
