import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Menu, MenuItemProps } from 'semantic-ui-react'

export const AdminMenu = () => {
    const [activeItem, setActiveItem] = useState<string>('')
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        setActiveItem(location.pathname)
    }, [location.pathname])

    const clickItem = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
        history.push(data!.name!)
    }
    
    
    return <Menu vertical size='large'>
        <Menu.Item>
            <Menu.Header>
                {/* <Icon name='users' /> */}
                Usuarios
            </Menu.Header>
            <Menu.Menu>
                <Menu.Item content='Nuevo usuario' name='/admin/users/create' icon='add circle' active={activeItem === '/admin/users/create'} onClick={clickItem} />
                <Menu.Item content='Ver todos los usuarios' icon='list alternate' name='/admin/users/list' active={activeItem === '/admin/users/list'} onClick={clickItem} />
            </Menu.Menu>
        </Menu.Item>

        <Menu.Item>
            <Menu.Header>Contenidos</Menu.Header>
            <Menu.Menu>
                <Menu.Item header>Cápsulas en Video</Menu.Item>
                <Menu.Item content='Nueva cápsula' icon='add square' name='/admin/courses/create' active={activeItem === '/admin/courses/create'} onClick={clickItem} />
                <Menu.Item content='Ver todas las cápsulas' icon='list alternate' name='/admin/courses/list' active={activeItem === '/admin/courses/list'} onClick={clickItem} />
                {/* <Divider /> */}
                <Menu.Item header>Categorías</Menu.Item>
                <Menu.Item content='Nueva categoría' icon='add square' name='/admin/courses/create-category' active={activeItem === '/admin/courses/create-category'} onClick={clickItem} />
                <Menu.Item content='Ver todas las categorías' icon='list alternate' name='/admin/courses/list-category' active={activeItem === '/admin/courses/list-category'} onClick={clickItem} />
            </Menu.Menu>
        </Menu.Item>

        <Menu.Item>
            <Menu.Header>Reportes</Menu.Header>
            <Menu.Menu>
                <Menu.Item content='Solicitar reporte actualizado' icon='file excel' name='/admin/report' active={activeItem === '/admin/report'} onClick={clickItem} />
            </Menu.Menu>
        </Menu.Item>
    </Menu>
}