import React, { useContext, useEffect, useState } from 'react'

import {
    Grid,
    Icon,
    Header,
    Button,
    Form,
    Checkbox
} from 'semantic-ui-react'

import './UserList.scss'

import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client'
import LoaderContext from '../../Library/Context/LoaderContext'
import useMobile from '../../Hooks/useMobile'
import TopBar from '../../Components/TopBar'

import { ADMIN_CREATE_USER } from './connections'
import { AdminMenu } from './AdminMenu'

export default function UserCreate() {
    const { isMobile } = useMobile()
    const { token } = useSelector((state: any) => state)

    const [formUser, setFormUser] = useState({
        firstName: '',
        lastName: '',
        mail: '',
        canTrainers: false,
        canInterna: false
    })
    const { setLoading } = useContext(LoaderContext)
    
    const [createUser, { loading, data, error }] = useMutation(ADMIN_CREATE_USER, {
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })
    
    useEffect(() => {
        setLoading(loading)
    }, [loading, setLoading])

    useEffect(() => {
        if (!isMobile && !loading && data?.UserLearningRoutes?.LearningRoute) {
            let menuWidth = [].slice.call(document.getElementsByClassName('MenuItemElement')).reduce((acc, lr: any) => {
                /* console.log({
                    word: lr.innerHTML,
                    length: lr.innerHTML.length + 0,
                    width: lr.offsetWidth,
                    letterWidth: lr.offsetWidth / lr.innerHTML.length
                }) */
                return acc + lr.offsetWidth
            }, 0)
            if (menuWidth > window.innerWidth) {
                // ¿Cuantos caben?
                // Promedio por letra: 12px.
            }
            /* for (const elementt of ) {
                menuWidth += elementt.offsetWidth
            } */
        }
    }, [isMobile, data, loading])

    const updateForm = (e: any, data: any) => {
        const { name, id, value, checked } = data
        const identifier = !name ? id : name
        const realValue = !value ? checked : value
        
        const update = {...formUser}
        // @ts-ignore
        update[identifier] = realValue
        setFormUser(update)
    }

    const submitForm = () => {
        createUser({
            variables: {
                AdminUserCreateInput: formUser
            }
        })
    }

    useEffect(() => {
        if (data?.adminCreateUser?.success) {
            alert(`El usuario fue creado con éxito, la clave es: "${data.adminCreateUser.password}" y fue notificado a "pruebasmercado@dcv.cl"."`)
        }
    }, [data])

    useEffect(() => {
        if (error) {
            alert(error)
        }
    }, [error])

    return <Grid className="ViewCourses">
        <TopBar />
        <Grid.Row columns="equal">
            <AdminMenu />
            
            <Grid.Column>
                <Grid.Row>
                    <Header as='h2'>
                        <Icon name='settings' />
                        <Header.Content>
                        Creación de usuario
                        <Header.Subheader>Registra un nuevo usuario para el acceso a la plataforma, la contraseña será enviada a <strong>pruebasmercado@dcv.</strong> y la podrás ver al finalizar la creación del usuario.</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Row>
                <Grid.Row>
                    <Form onSubmit={(event, data) => {
                        submitForm()
                    }}>
                        <Form.Input id='firstName' label='Nombre(s)' placeholder='Luis Alejandro' onChange={updateForm} />
                        <Form.Input id='lastName' label='Apellidos(s)' placeholder='Moreno Rubio' onChange={updateForm} />
                        <Form.Input id='mail' label='Correo electrónico' placeholder='alejandro.rubio@learnlab.cl' onChange={updateForm} />
                        <Form.Field>
                            <Checkbox
                                id='canTrainers'
                                onChange={updateForm}
                                label='Añadir el participante a Trainers.' />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                id='canInterna'
                                onChange={updateForm}
                                label='Añadir el participante a Capacitación Interna.' />
                        </Form.Field>
                        <Button>Enviar</Button>
                    </Form>
                </Grid.Row>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}