import React, { useContext, useEffect, useState } from 'react'

import {
    Grid,
    Icon,
    Header,
    Button,
    Form,
    Divider,
    Checkbox,
    List
} from 'semantic-ui-react'

import './UserList.scss'

import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client'
import LoaderContext from '../../Library/Context/LoaderContext'

import TopBar from '../../Components/TopBar'

import { ADMIN_CREATE_CATEGORY, ADMIN_GET_CATEGORY_LIST } from './connections'
import { AdminMenu } from './AdminMenu'

import { useHistory, useParams } from 'react-router-dom';
import { logout, updateRoutes } from '../../Store/Actions';

type FormCategory = {
    /** Nombre de la categforía. */
    name: string
    /** Descripción de la categoría. */
    description: string
    /** Ubicación de la categoría. */
    sortOrder: number
    /** Ícono de la categoría. */
    icon: string
    /** Categoría disponible para los Trainers. */
    showToTrainers: boolean
    /** Categoría disponible para Capacitación Interna. */
    showToInterna: boolean
    /** Listado de cápsulas actuales. */
    capsules?: any[]
}

export default function CategoryCreate() {
    
    const { token, learningRoutes } = useSelector((state: any) => state)
    const { categoryId } = useParams<any>()

    const dispatch = useDispatch()
    const history = useHistory()
    const isEdit = !!categoryId

    const [formUser, setFormUser] = useState<FormCategory>({
        name: '',
        description: '',
        sortOrder: -1,
        icon: 'shield alternate',
        showToTrainers: false,
        showToInterna: false,
        // capsules: []
    })
    const { setLoading } = useContext(LoaderContext)
    
    const [createCategory, { loading, data, error }] = useMutation(ADMIN_CREATE_CATEGORY, {
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })

    const [getData, editData] = useLazyQuery(ADMIN_GET_CATEGORY_LIST, {
        onError: (error) => {
            if (error.message === 'Unauthenticated') {
                dispatch(logout(null))
                return history.push('/', {
                    error
                })
            }
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })

    /**
     * Si cambia el categoryId de la URL, reobtengo información de edición.
     * @listen @categoryId
     */
    useEffect(() => {
        if(categoryId) {
            const localId = Number(categoryId)
            getData({
                variables: {
                    AdminCategoryListInput: {
                        id: localId
                    }
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId])

    /**
     * Cuando se recibe la data para editar.
     * @listen @editData
     */
    useEffect(() => {
        if (editData?.data?.adminGetCategoryList?.categories?.length > 0) {
            const edited = editData?.data?.adminGetCategoryList?.categories[0]
            // console.log(edited)
            setFormUser({
                name: edited.name,
                description: edited.description,
                sortOrder: edited.sortOrder,
                icon: edited.icon,
                showToTrainers: edited.showToTrainers,
                showToInterna: edited.showToInterna,
            })
        }
        // if(editData?.data?.getCourseDetail) {
        //     const { Course, LearningResources: [Resource] } = editData?.data?.getCourseDetail

        //     const { name, description, learningRouteId } = Course
        //     const { configuration: rawConfig, description: goal, extendedDescription: rawDescription, timeline: rawTimeline } = Resource
        //     const config = JSON.parse(rawConfig)
             
        //     console.log({
        //         description,
        //         rawDescription
        //     })

        //     setFormUser({
        //         learningRouteId,
        //         title: name,
        //         goal,
        //         url: config.url,
        //         timeline: JSON.parse(rawTimeline),
        //         relatedPills: [],
        //         description: {
        //             internal: rawDescription,
        //             external: description
        //         }
        //     })

        //     const contentBlock = htmlToDraft(rawDescription)
        //     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        //     const editorState = EditorState.createWithContent(contentState);
        //     setEditor(editorState)
        //     setProgress(100)
        // }
    }, [editData.data])
    
    /** 
     * Cuando se está a la espera de una query.
     * @listen @loading @editData.loading
     */
    useEffect(() => {
        setLoading(loading || editData.loading)
    }, [loading, setLoading, editData.loading])

    /**
     * Muestra la alerta y redirige cuando se guardan los datos en la base de datos.
     * @listen @data
     */
    useEffect(() => {
        if (data?.adminCreateCategory?.success) {
            const { learningRoutes } = data.adminCreateCategory
            console.log({ learningRoutes, data })
            dispatch<any>(
              updateRoutes({
                  learningRoutes
              })
            )
            alert('Nueva categoría creada con éxito')
            history.push('/admin/courses/list-category')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    /**
     * Manejo de errores.
     * @listen @error
     */
    useEffect(() => {
        if (error) {
            alert(error)
        }
    }, [error])

    /**
     * 
     * @example 
     * updateForm(null, { name: 'key', value: 'value' })
     * updateForm(null, { name: data.id, value: data.value })
     * updateForm(null, { name: data.name, value: data.checked })
     */
    const updateForm = (e: any, data: any) => {
        const { name, id, value, checked } = data
        const identifier = !name ? id : name
        const realValue = !value ? checked : value
        
        const update = {...formUser}
        // @ts-ignore
        update[identifier] = realValue
        setFormUser(update)
    }

    const submitForm = () => {
        /**
         * @todo Validate.
         */
        const variables = {
            AdminCreateCategoryInput: {
                ...formUser,
                id: 0
            }
        }
        if (categoryId) {
            // Editando
            variables.AdminCreateCategoryInput.id = Number(categoryId)
        }
        window.scrollTo(0, 0)

        console.log(variables)
        createCategory({
            variables
        })
    }

    const AVAILABLE_ICONS = [
        'shield alternate',
        'group',
        'legal',
        'stack exchange',
        'chart line',
        'key',
        'certificate',
    ]

    return <Grid className="ViewCourses">
        <TopBar />
        <Grid.Row columns="equal">
            <AdminMenu />
            
            <Grid.Column>
                <Grid.Row>
                    <Header as='h2' style={{
                        marginBottom: 16
                    }}>
                        <Icon name='settings' />
                        <Header.Content>
                        {isEdit ? 'Edición' : 'Creación'} de Categoría
                        <Header.Subheader>Registra una nueva categoría para agrupar cápsulas en video.</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Row>
                <Grid.Row>
                    <Form onSubmit={(event, data) => {
                        submitForm()
                    }}>
                        <Form.Input required id='name' value={formUser.name} label='Nombre' placeholder='ejemplo: Seguridad' onChange={updateForm} />
                        <Form.TextArea required id='description' value={formUser.description} label='Descripción' placeholder='ejemplo: Cápsulas de Aprendizaje para Ciberseguridad' onChange={updateForm} />
                        {/* <Form.Field label='Orden (antes de):' /> */}
                        <Form.Select  label='Orden (después de):' value={formUser.sortOrder} defaultValue={-1} fluid options={[...learningRoutes.reduce((acc: any[], route: any) => {
                            acc.push({
                                key: route.id,
                                value: route.id,
                                text: route.name
                            })
                            return acc
                        }, [
                            {
                                key: -1,
                                value: -1,
                                text: 'Añadir la principio'
                            },
                            {
                                content: <Divider style={{
                                    marginTop: 0,
                                    marginBottom: 0
                                }} />,
                                disabled: true
                            }
                        ]),
                        {
                            content: <Divider style={{
                                marginTop: 0,
                                marginBottom: 0
                            }} />,
                            disabled: true
                        },
                        {
                            key: learningRoutes[learningRoutes.length - 1].id + 1,
                            value: learningRoutes[learningRoutes.length - 1].id + 1,
                            text: 'Añadir al final'
                        }]} onChange={(e, { value }) => {
                            const update = {...formUser}
                            // @ts-ignore
                            update.sortOrder = value
                            setFormUser(update)
                        }} />
                        <Form.Field label='Icono' />
                        <List selection horizontal>
                            {AVAILABLE_ICONS.map(icon => <List.Item onClick={() => {
                                updateForm(null, { name: 'icon', value: icon })
                            }}>
                                <Icon 
                                    // @ts-ignore
                                    name={icon} size='big' color={formUser.icon === icon ? 'black' : undefined} />
                                {/* <List.Content>
                                    <List.Header>Helen</List.Header>
                                </List.Content> */}
                            </List.Item>)}
                        </List>
                        <br />
                        <br />
                        <br />
                        <Form.Field>
                            <Checkbox
                                id='showToTrainers'
                                checked={formUser.showToTrainers}
                                onChange={updateForm}
                                label='Disponible para Trainers.' />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                id='showToInterna'
                                checked={formUser.showToInterna}
                                onChange={updateForm}
                                label='Disponible para Capacitación Interna.' />
                        </Form.Field>
                        <br />
                        <Button>Guardar</Button>
                    </Form>
                </Grid.Row>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}