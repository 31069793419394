import React, { useContext, useEffect, useState } from 'react'

import {
    Grid,
    Menu,
    Input,
    Icon,
    Card,
    Header,
    Button,
    Form,
    Segment
} from 'semantic-ui-react'
import { NoCourseImage } from '../Assets'

import './Courses.scss'
import { Course } from './types'

import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { logout, updateRoutes } from '../Store/Actions'
import { useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_ROUTES_LIST, USER_LEARNING_ROUTES } from './connections'
import LoaderContext from '../Library/Context/LoaderContext'
import useMobile from '../Hooks/useMobile'
import TopBar from '../Components/TopBar'

import Scroll from 'react-scroll'
const { Element, scroller } = Scroll

export default function Courses() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { isMobile } = useMobile()
    const { token, learningRoutes } = useSelector((state: any) => state)
    const [activeItem, setActiveItem] = useState<string>('11-general')
    const [searching, setSearching] = useState<boolean>(false)
    const [form, setForm] = useState({
        search: '',
        learningRouteId: 0
    })
    
    const { setLoading } = useContext(LoaderContext)
    const { loading, data } = useQuery(USER_LEARNING_ROUTES, {
        onError: (error) => {
            if (error.message === 'Unauthenticated') {
                dispatch(logout(null))
                return history.push('/', {
                    error
                })
            }
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })

    const [search, { loading: searchLoading, data: searchData }] = useLazyQuery(GET_ROUTES_LIST, {
        onError: (error) => {
            if (error.message === 'Unauthenticated') {
                dispatch(logout(null))
                return history.push('/', {
                    error
                })
            }
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })

    useEffect(() => {
        setLoading(loading || searchLoading)
    }, [loading, searchLoading, setLoading])

    useEffect(() => {
        if (!isMobile && !loading && data?.UserLearningRoutes?.LearningRoute) {
            let menuWidth = [].slice.call(document.getElementsByClassName('MenuItemElement')).reduce((acc, lr: any) => {
                /* console.log({
                    word: lr.innerHTML,
                    length: lr.innerHTML.length + 0,
                    width: lr.offsetWidth,
                    letterWidth: lr.offsetWidth / lr.innerHTML.length
                }) */
                return acc + lr.offsetWidth
            }, 0)
            if (menuWidth > window.innerWidth) {
                // ¿Cuantos caben?
                // Promedio por letra: 12px.
            }
            /* for (const elementt of ) {
                menuWidth += elementt.offsetWidth
            } */
        }
    }, [isMobile, data, loading])

    useEffect(() => {
        if (data?.UserLearningRoutes) {
            const learningRoutesFetch = data.UserLearningRoutes.LearningRoute.reduce((acc: any, route: any) => {
                acc.push({
                    id: route.id,
                    name: route.name
                })
                return acc
            }, [])
            if (learningRoutesFetch.length > learningRoutes.length) {
                dispatch<any>(
                  updateRoutes({
                      learningRoutes
                  })
                )
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const handleItemClick = (event: any, name: any) => {
        console.log({ name })
        setActiveItem(name)
        setSearching(false)
        scroller.scrollTo(`category-${name}`, {
            duration: 750,
            delay: 24,
            smooth: true,
            offset: -24
        })
    }

    const updateForm = (e: any, { name, value }: any) => {
        const update = {...form}
        // @ts-ignore
        update[name] = value
        setForm(update)
    }


    return <Grid className="ViewCourses">
        <TopBar setSearching={setSearching} centerColumn={<Input icon placeholder='Escribe aquí para iniciar una búsqueda...' fluid className="SearchBar" value={form.search} name="search" onChange={updateForm}>
                <input onKeyPress={(event) => {
                    if (event.key === 'Enter' && form.search.length > 0) {
                        search({
                            variables: {
                                getRoutesListInput: form
                            }
                        })
                        setSearching(true)
                    }
                }} />
                <Icon name='search' />
            </Input>} />
        <Grid.Row>
            <Menu pointing secondary fluid scrolling>
                {data?.UserLearningRoutes?.LearningRoute.map((lr: Course.LearningRoute) => <Menu.Item
                    name={lr.name}
                    active={activeItem === lr.slug}
                    onClick={e => handleItemClick(e, lr.slug)}
                    className='MenuItemElement'
                    key={`Menu ${lr.name}`}
                    />)}
            </Menu>
        </Grid.Row>
        {!searching && <Grid.Row className="CourseList">
            {data?.UserLearningRoutes?.LearningRoute.map((lr: Course.LearningRoute) => {
                return <Element name={`category-${lr.slug}`} className="CategoryWrapper" key={`CategoryId${lr.id}`} id={`category-${lr.slug}`}>
                <Header as='h2'>
                    <Icon name={lr.icon} />
                    <Header.Content>
                    {lr.name}
                    <Header.Subheader>{lr.description}</Header.Subheader>
                    </Header.Content>
                </Header>
                <Card.Group itemsPerRow={isMobile ? 1 : 4}>
                {lr.Courses.map(course => {
                    const image = course && course.image && course.image.length > 0 ? course.image : NoCourseImage
                    return <Card
                    image={image}
                    header={course.name}
                    onClick={() => history.push(course.href)}
                    description={course.description}
                    className={`${course.enabled ? 'EnabledCard' : 'DisabledCard'}`}
                    extra={<Button primary={course.enabled} size='tiny' onClick={() => history.push(course.href)}>{course.enabled ? 'Entrar' : 'Ver nuevamente'}</Button>}
                />})}
                </Card.Group>
            </Element>
        })}            
        </Grid.Row>}
        {searching && <Grid.Row className="CourseList Searching">
            <Header as='h3' fluid>
                <Header.Content>
                Buscador
                <Header.Subheader>Si necesitas encontrar un contenido en especìfico, completa alguno de los filtros.</Header.Subheader>
                </Header.Content>
            </Header>
            <Form fluid widths='equal' inline onSubmit={() => search({
                variables: {
                    getRoutesListInput: form
                }
            })}>
                <Form.Group>
                    <Form.Input
                        placeholder='Ingresa los términos de búsqueda...'
                        name='search'
                        fluid
                        className="SearchInputBar"
                        value={form.search}
                        onChange={updateForm}
                    />
                    <Form.Select
                        options={[{ key: 0, text: 'Todas las categorías', value: 0 }, ...data?.UserLearningRoutes?.LearningRoute.map((lr: Course.LearningRoute) => {
                            return { key: lr.id, text: lr.name, value: lr.id }
                        })]}
                        placeholder='Todas las categorías'
                        className="LearningRouteSelector"
                        value={form.learningRouteId}
                        name="learningRouteId"
                        onChange={updateForm}
                    />
                    <Form.Button content='BUSCAR' className="BuscarBtn" primary size='tiny' />
                </Form.Group>
            </Form>
            {searchData?.getRoutesList?.LearningRoute?.map((lr: Course.LearningRoute) => {
                return <Card.Group itemsPerRow={isMobile ? 1 : 4} key={`Group ${lr.name}`}>
                {lr.Courses.map((course, ndx) => {
                    const image = course && course.image && course.image.length > 0 ? course.image : NoCourseImage
                    return <Card
                    image={image}
                    key={`Course-${ndx} ${course.name}`}
                    header={course.name}
                    onClick={() => history.push(course.href)}
                    description={course.description}
                    className={`${course.enabled ? 'EnabledCard' : 'DisabledCard'}`}
                    extra={<Button primary size='tiny' onClick={() => history.push(course.href)}>Entrar</Button>}
                />})}
                </Card.Group>
            })}        
            {searchData?.getRoutesList?.LearningRoute?.length === 0 && form.search.length > 0 && <Segment className="emptySearch" placeholder fluid>
                <Header icon>
                <Icon name='search' />
                    No se han encontrado resultados con tu búsqueda.
                </Header>
                <Segment.Inline>
                <Button primary basic onClick={() => {
                    updateForm(null, {
                        name: 'search',
                        value: ''
                    })
                }}>Limpiar filtros</Button>
                </Segment.Inline>
            </Segment>}
        </Grid.Row>}
    </Grid>
}