import React, { useContext, useState } from 'react'

import {
    Grid,
    Icon,
    Header,
    Button,
    Form,
} from 'semantic-ui-react'

import './UserList.scss'

import { useSelector } from 'react-redux';
import LoaderContext from '../../Library/Context/LoaderContext'
import useMobile from '../../Hooks/useMobile'
import TopBar from '../../Components/TopBar'

import { AdminMenu } from './AdminMenu'

export default function Report() {
    const { isMobile } = useMobile()
    const { token } = useSelector((state: any) => state)

    const [formUser] = useState({
        firstName: '',
        lastName: '',
        mail: '',
        canTrainers: false,
        canInterna: false
    })
    
    const { setLoading } = useContext(LoaderContext)
    

    const submitForm = () => {
        setLoading(false)
        console.log({ isMobile, token, formUser })
        // createUser({
        //     variables: {
        //         AdminUserCreateInput: formUser
        //     }
        // })
    }

    return <Grid className="ViewCourses">
        <TopBar />
        <Grid.Row columns="equal">
            <AdminMenu />
            
            <Grid.Column>
                <Grid.Row>
                    <Header as='h2'>
                        <Icon name='envelope' />
                        <Header.Content>
                        Solicitud de reporte
                        <Header.Subheader>Solicita el reporte a tu correo electrónico.</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Row>
                <Grid.Row>
                    <Form style={{
                        marginTop: '12px'
                    }} onSubmit={(event, data) => {
                        submitForm()
                    }}>
                        <Button primary>Solicitar reporte</Button>
                    </Form>
                </Grid.Row>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}