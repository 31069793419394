import React from 'react';

import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import {
  Dimmer,
  Loader
} from 'semantic-ui-react'

import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client'
import fetch from 'cross-fetch';

import { LoaderProvider } from './Library/Context/LoaderContext';

import { Provider } from 'react-redux'
import configureStore from './Store'
import { PersistGate } from 'redux-persist/integration/react'


import './App.scss'
import 'semantic-ui-css/semantic.min.css'
import useMobile from './Hooks/useMobile';
import { AdminRoute, PrivateRoute, PublicRoute } from './Routes';

/** Generic views. */
import Home from './Views/Home';
import Verify from './Views/Verify';

/** Student views. */
import Courses from './Views/Courses';
import CourseView from './Views/CourseView';

/** Administrator views. */
import UserList from './Views/Admin/UserList';
import UserCreate from './Views/Admin/UserCreate';
import Report from './Views/Admin/Report';
import PillCreate from './Views/Admin/PillCreate';

import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PillList from './Views/Admin/PillList';
import CategoryList from './Views/Admin/CategoryList';
import CategoryCreate from './Views/Admin/CategoryCreate';

const { persistor, store } = configureStore()

/* const httpLink = createHttpLink({
  uri: process.env.NODE_ENV === 'production' ? 'https://dcve-api.biggroup.cl/graphql' : 'http://localhost:4000/graphql'
}) */

/* const authLink = setContext((_, { headers }) => {
  const data = JSON.parse(localStorage.getItem('persist:dcve')!)
  const { token } = data
  console.log({ token, data })
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
}) */

const uri = true || process.env.NODE_ENV === 'production' ? 'https://api.dcvevolucion.cl/graphql' : 'http://localhost:4000/graphql'

const client = new ApolloClient({
  uri,
  link: new HttpLink({ uri, fetch }),
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions: {
    mutate: {
      errorPolicy: 'all'
    }
  },
});

export default function App() {
  const [loading, setLoading] = React.useState<boolean>(false)
  const { ViewportClass } = useMobile()

  return <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
            <LoaderProvider value={{ loading, setLoading }}>
              <Dimmer active={loading} inverted>
                <Loader size='large'>Cargando tu información...</Loader>
              </Dimmer>
              <Router>
                <div className={`App ${ViewportClass}`}>
                  <Switch>
                    <PublicRoute restricted={true} component={Home} path="/" exact />
                    <PrivateRoute component={Courses} path="/courses" exact />
                    <PrivateRoute component={CourseView} path="/course/view/:learningRouteId/:courseId" />
                    <AdminRoute component={UserList} path="/admin/users/list" />
                    <AdminRoute component={UserCreate} path="/admin/users/create" exact />
                    <AdminRoute component={PillCreate} path="/admin/courses/create" exact />
                    <AdminRoute component={PillCreate} path="/admin/courses/edit/:learningRouteId/:resourceId" exact />
                    <AdminRoute component={PillList} path="/admin/courses/list" exact />
                    <AdminRoute component={CategoryList} path="/admin/courses/list-category" exact />
                    <AdminRoute component={CategoryCreate} path="/admin/courses/create-category" exact />
                    <AdminRoute component={CategoryCreate} path="/admin/courses/edit-category/:categoryId" exact />
                    <AdminRoute component={Report} path="/admin/report" exact />
                    <PublicRoute restricted={true} component={Verify} path="/verify/:token" />
                  </Switch>
                </div>
            </Router>
          </LoaderProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
}
