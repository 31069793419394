import { useContext, useEffect } from 'react'


import useMobile from '../Hooks/useMobile'
import { useHistory, useParams } from 'react-router-dom'

import './Home.scss'
import { useMutation } from '@apollo/client'
import { USER_VALIDATE_ACCOUNT } from './connections'
import LoaderContext from '../Library/Context/LoaderContext'
import { useDispatch } from 'react-redux'
import { attemptLogin } from '../Store/Actions'



export default function Verify() {
    const { token } = useParams<any>()
    const { ViewportClass } = useMobile()
    const history = useHistory()
    const dispatch = useDispatch()
    const { setLoading } = useContext(LoaderContext)

    const [validateAccount, { data }] = useMutation(USER_VALIDATE_ACCOUNT, {
        onError: (error) => {
            alert(error)
            // ir a home
            console.log({ error })
            history.push('/')
        },
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        setLoading(true)
        validateAccount({
            variables: {
                UserValidateInput: {
                    token
                }
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (data?.UserValidate?.success) {
            const isLogged = dispatch<any>( attemptLogin(data?.UserValidate) )
            if (isLogged) {
                history.push('/courses')
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return <div className={`ViewHome ${ViewportClass}`}>
        
    </div>
}