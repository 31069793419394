import { useContext, useEffect, useState } from 'react'

import {
    Grid,
    Form,
    Button,
    Icon,
    Message,
    Modal,
    Header,
    // Types
    InputOnChangeData,
    CheckboxProps,
    Input,
    Divider,
    List
} from 'semantic-ui-react'

import { ReactComponent as Logo } from '../Assets/Logotipo.svg'
import useMobile from '../Hooks/useMobile'
import { Link, useHistory, useLocation } from 'react-router-dom'

import './Home.scss'
import LoaderContext from '../Library/Context/LoaderContext'
import { RECOVER_PASSWD, USER_CHECKDOMAIN, USER_LOGIN, USER_REGISTER } from './connections'
import { useMutation } from '@apollo/client'

import { attemptLogin } from '../Store/Actions'
import { useDispatch } from 'react-redux'

import Lottie from 'react-lottie'
import animationData from '../Assets/Exito.json'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Home() {
    const history = useHistory()
    const location = useLocation<any>()
    const dispatch = useDispatch()
    const query = useQuery()
    const [enrollment, setEnrollment] = useState({
        enabled: false,
        domain: '',
        mail: ''
    })

    const [UserLoginInput, setForm] = useState({
        mail: '',
        password: '',
        trust: false
    })

    const { ViewportClass } = useMobile()
    const { setLoading } = useContext(LoaderContext)
    const [userLogin, { data, loading, error }] = useMutation(USER_LOGIN, {
        // onError: (error) => {
        //     console.log({ error })
        // }
    })
    const [doRecover, recoverPasswdEvents] = useMutation(RECOVER_PASSWD)
    // Validate form
    const [checkDomain, checkDomainEvents] = useMutation(USER_CHECKDOMAIN)
    const [validMail, setValidMail] = useState<boolean>(false)

    const [expires, setExpires] = useState<boolean>(false)
    const [showingVideo, showVideo] = useState<boolean>(false)

    const [formEnrollment, setFormEnrollment] = useState<any>()
    const [enableButton, setEnableButton] = useState<any>()
    const [stepRegister, setStepRegister] = useState(0)

    const [errorForm, setErrorForm] = useState<any>()
    const [errorName, setErrorName] = useState<any>()
    const [errorLastname, setErrorLastname] = useState<any>()

    const [autoEnrollment, autoEnrollmentState] = useMutation(USER_REGISTER, {
        onError: (error) => {
            alert(error)
        },
        fetchPolicy: 'no-cache'
    })

    const [passLength, setPassLength] = useState(false)
    const [passNumber, setPassNumber] = useState(false)
    const [passMayus, setPassMayus] = useState(false)

    type MessagePlatform = {
        type?: 'negative' | 'positive',
        message: string
    }
    const [messageLogin, setMessageLogin] = useState<MessagePlatform>({
        type: undefined,
        message: ''
    })
    
    const [messageEnrollment, setMessageEnrollment] = useState<MessagePlatform>({
        type: undefined,
        message: ''
    })

    const reset = () => {
        setMessageLogin({
            type: undefined,
            message: ''
        })
        setMessageEnrollment({
            type: undefined,
            message: ''
        })
        setForm({
            mail: '',
            password: '',
            trust: false
        })
    }

    const validateForm = (updated: any) => {
        if (!validMail) {
            return 0
        }
        // Validations actions
        let passed = 0
        if (updated?.newPassword) {
            setPassLength(updated?.newPassword.length >= 8)
            passed = passed + (updated?.newPassword.length >= 8 ? 1 : 0)

            const patternNumber = new RegExp( "^(?=.*\\d).+$" );
            setPassNumber(patternNumber.test(updated?.newPassword))
            passed = passed + (patternNumber.test(updated?.newPassword) ? 1 : 0)

            const passMayus = new RegExp( "^(?=.*[a-z])(?=.*[A-Z]).+$" );
            setPassMayus(passMayus.test(updated?.newPassword))
            passed = passed + (passMayus.test(updated?.newPassword) ? 1 : 0)
        }
        
        // if (updated?.newPassword && updated?.repeatNewPassword) {
        //     const { newPassword, repeatNewPassword } = updated
        // }
        if ((updated?.newPassword?.length || 0) + (updated?.repeatNewPassword?.length || 0) === 0) {
            setErrorForm('El ingreso de una contraseña es obligatorio.')
        } else if (passed !== 3) {
            setErrorForm('La contraseña ingresada no cumple con el estándar de seguridad solicitado.')
        } else {
            if (updated?.newPassword !== updated?.repeatNewPassword) {
                setErrorForm('La contraseña ingresada no es idéntica.')
            } else {
                setErrorForm(undefined)
            }
        }

        const nameErr = (updated?.name === '' && updated?.name?.trim().length === 0) || updated.name === undefined
        setErrorName(nameErr ? `El ingreso de tu nombre es obligatorio para continuar el registro.` : undefined)
        
        const lastnameErr = (updated?.lastName === '' && updated?.lastName?.trim().length === 0) || updated.lastName === undefined

        setErrorLastname(lastnameErr ? `El ingreso de tu apellido es obligatorio para continuar el registro.` : undefined)

        // Enable button
        setEnableButton(Object.keys(updated).length === 5 && updated?.repeatNewPassword === updated?.newPassword && updated?.name?.length > 0 && updated?.lastName?.length > 0 && updated?.mail?.length > 0 && validMail)
    }

    const updateForm = (e: any, { id, value }: any) => {
        const updated = {...formEnrollment}
        // @ts-ignore
        updated[id] = value
        setFormEnrollment(updated)

        validateForm(updated)
    }

    useEffect(() => {
        // console.log({ error, data })
        if (error) {
            setMessageLogin({
                type: 'negative',
                message: error.message
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (recoverPasswdEvents.error) {
            setMessageLogin({
                type: 'negative',
                message: recoverPasswdEvents.error.message
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recoverPasswdEvents.error])

    useEffect(() => {
        if (checkDomainEvents.error) {
            setMessageEnrollment({
                type: 'negative',
                message: checkDomainEvents.error.message
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkDomainEvents.error])

      useEffect(() => {
        if (recoverPasswdEvents.data?.UserRecoverPasswd?.success) {
            setMessageLogin({
                type: 'positive',
                message: 'Se ha solicitado la recuperación de contraseña con éxito. Recibirás un correo electrónico con la información en los próximos minutos.'
            })
        }

    }, [recoverPasswdEvents.data])

    // useEffect(() => {
    //     if (recoverPasswdEvents.loading || loading) {
    //         setMessageLogin({
    //             type: undefined,
    //             message: ''
    //         })
    //     }
    // }, [recoverPasswdEvents.loading, loading])

    useEffect(() => {
        setLoading(loading || recoverPasswdEvents.loading || checkDomainEvents.loading)
    }, [loading, setLoading, recoverPasswdEvents.loading, checkDomainEvents.loading])


    useEffect(() => {
        if (location.state && location.state.error?.message === 'Unauthenticated') {
            setExpires(true)
        }
    }, [location, location.state])
    

    useEffect(() => {
        if (data?.UserLogin?.success) {
            const { token, GroupInfo, state, text } = data.UserLogin
            switch(state) {
                case 'OK_LOGIN':
                    const isLogged = dispatch<any>(
                        attemptLogin({
                            token,
                            GroupInfo
                        })
                    )
                    if (isLogged) {
                        const next = query.get('next')
                        if (next) {
                            history.push(next)
                        } else {
                            history.push('/courses')
                        }
                    }
                    break
                case 'AUTOENROLLMENT':
                    setEnrollment({
                        enabled: true,
                        domain: text,
                        mail: UserLoginInput.mail
                    })
                    break
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dispatch, history])

    useEffect(() => {
        if (autoEnrollmentState?.data?.UserRegister?.success) {
            setStepRegister(stepRegister+1)
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoEnrollmentState.data])

    useEffect(() => {
        const { data, error } = checkDomainEvents
        
    
        if (data?.CheckDomain?.success) {
            setValidMail(true)
            console.log({
                checkDomainEvents
            })
        } else {
            setValidMail(!enrollment.mail.length ? false : !error)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkDomainEvents.data, checkDomainEvents.error])

    const updateChange = (event: any, data: any) => {
        const internal: InputOnChangeData | CheckboxProps = data
        const name = internal.name
        const value = internal.value
        const checked = internal.checked

        const changed: string | boolean = checked === undefined ? value : checked

        const update = {...UserLoginInput}
        
        // @ts-ignore
        update[name] = changed
        setForm(update)
    }

    const validateMail = () => {
        const mail = formEnrollment?.mail?.trim()
        if (mail?.length > 0) {
            checkDomain({
                variables: {
                    CheckDomainInput: {
                        mail
                    }
                }
            })
        }
    }

    const recoverPasswd = () => {
        const { mail } = UserLoginInput

        doRecover({
            variables: {
                UserRecoverPasswdInput: {
                    mail
                }
            }
        })
    }


    return <div className={`ViewHome ${ViewportClass}`}>
        <Modal onClose={() => showVideo(false)} open={showingVideo}>
            <Modal.Content image className='VideoDCVWelcome'>
                <video autoPlay controlsList='nodownload' playsInline controls className="videoModal" src='https://cdn.dcvevolucion.cl/capsules/IntroDCV.mp4' />
            </Modal.Content>
        </Modal>

        <Modal
            centered={false}
            open={enrollment.enabled}
            closeOnDimmerClick={false}
            closeOnDocumentClick={false}
            size='small'
            onClose={() => {
                reset()
                setEnrollment({
                    enabled: false,
                    domain: '',
                    mail: ''
                })
                setStepRegister(0)
                setValidMail(false)
                setFormEnrollment({})
            }}
            >
            <Modal.Header>{stepRegister === 0 ? 'Formulario de Autoinscripción' : 'Registro exitoso en Sitio de Cápsulas DCVe'}</Modal.Header>
            <Modal.Content>
                {stepRegister === 1 && <>
                    <Lottie options={{
                    animationData,
                    autoplay: true,
                    loop: false
                    }}
                        height={256}
                        width={256}/>
                    <Modal.Description style={{ textAlign: 'center' }}>
                        A continuación, recibirás un correo electrónico que te permitirá ingresar al Sitio de Cápsulas DCVe.
                    </Modal.Description>
                        
                </>}
                {stepRegister === 0 && <>
                <Modal.Description>
                    Para registrarte en el Sitio de Cápsulas DCVe, debes ingresar:
                </Modal.Description>
                
                <Form autoComplete="off"><br />
                    <Form.Field
                        id='mail'
                        control={Input}
                        label='Correo electrónico'
                        placeholder='Correo electrónico institucional'
                        onChange={updateForm}
                        autoComplete="off"
                        disabled={checkDomainEvents.loading || validMail}
                    />

                    {/* {!checkDomainEvents.loading && checkDomainEvents.error && <Message negative>
                            <Message.Header>Ha ocurrido un error</Message.Header>
                        {checkDomainEvents.error && <p>{checkDomainEvents.error?.message}</p>}
                    </Message>} */}

                    {/* {messageEnrollment.message.length > 0 && <Message negative>
                            <Message.Header>Ha ocurrido un error</Message.Header>
                        {checkDomainEvents.error && <p>{checkDomainEvents.error?.message}</p>}
                    </Message>} */}

                    {!validMail && messageEnrollment.message.length > 0 && <Message positive={messageEnrollment.type === 'positive'} negative={messageEnrollment.type === 'negative'}>
                        <Message.Header>{messageEnrollment.type === 'positive' ? 'Autorización' : 'Ha ocurrido un error'}</Message.Header>
                        <p>{messageEnrollment.message}</p>
                    </Message>}
                    
                    {(validMail && !checkDomainEvents.loading) && <>
                        <Form.Field
                            id='name'
                            control={Input}
                            label='Nombre'
                            error={errorName}
                            placeholder='Escribe tu primer nombre'
                            onChange={updateForm}
                        />
                        <Form.Field
                            id='lastName'
                            control={Input}
                            label='Apellido'
                            error={errorLastname}
                            placeholder='Escribe tu apellido'
                            onChange={updateForm}
                        />
                        <List>
                            <List.Item>
                                <Icon name={passLength ? 'check' : 'x'} />
                                <List.Content> <List.Description> La contraseña tiene al menos 8 carácteres.</List.Description> </List.Content>
                            </List.Item>
                            <List.Item>
                                <Icon name={passNumber ? 'check' : 'x'} />
                                <List.Content> <List.Description> La contraseña contiene al menos un número.</List.Description> </List.Content>
                            </List.Item>
                            <List.Item>
                                <Icon name={passMayus ? 'check' : 'x'} />
                                <List.Content> <List.Description> La contraseña contiene al menos una letra mayúscula. </List.Description> </List.Content>
                            </List.Item>
                        </List>
                        <Form.Field
                            id='newPassword'
                            control={Input}
                            label='Nueva contraseña'
                            placeholder='Nueva contraseña'
                            onChange={updateForm}
                            type='password'
                            autoComplete="off"
                        />
                        <Form.Field
                            id='repeatNewPassword'
                            control={Input}
                            label='Repetir nueva contraseña'
                            placeholder='Repetir nueva contraseña'
                            error={errorForm}
                            onChange={updateForm}
                            type='password'
                            autoComplete="off"
                        />
                    </>}
                </Form>
                </>}
            </Modal.Content>
            <Modal.Actions>
                {stepRegister !== 1 && <Button loading={checkDomainEvents.loading} disabled={autoEnrollmentState.loading} onClick={() => {
                    reset()
                    setEnrollment({
                        enabled: false,
                        domain: '',
                        mail: ''
                    })
                    setStepRegister(0)
                    setValidMail(false)
                    setFormEnrollment({})
                }}>Cancelar</Button>}
                <Button primary loading={autoEnrollmentState.loading || checkDomainEvents.loading} onClick={() => {
                    if (!validMail) {
                        validateMail()
                    } else {
                        if (stepRegister === 0) {
                            if (enableButton) {
                                autoEnrollment({
                                    variables: {
                                        UserRegisterInput: formEnrollment
                                    }
                                })
                            } else {
                                // Make errors
                                validateForm(formEnrollment)
                            }
                        } else {
                            reset()
                            setEnrollment({
                                enabled: false,
                                domain: '',
                                mail: ''
                            })
                            setStepRegister(0)
                            setValidMail(false)
                            setFormEnrollment({})
                        }
                    }
                }}>{!validMail ? 'Validar' : stepRegister === 0 ? 'Regístrate' : 'Volver'}</Button>
            </Modal.Actions>
        </Modal>
        
        <Modal dimmer={'inverted'} basic closeOnEscape={false} closeOnDimmerClick={false} open={expires}>
            <Header icon>
                <Icon name='time' />
                Sesión expirada
            </Header>
            <Modal.Content>
                <p>
                Tu sesión ha sido cerrada por inactividad. Puedes volver a ingresar a la Plataforma DCVe.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' inverted onClick={() => setExpires(false)}>
                <Icon name='checkmark' /> Nueva conexión
                </Button>
            </Modal.Actions>
        </Modal>
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Logo />
                    <h2>{('Bienvenido/a al Sitio de Cápsulas DCVe')}</h2>
                    <p>Plataforma donde podrás consultar y aprender fácilmente del proyecto DCVe.</p>

                    <Form onSubmit={() => {
                        recoverPasswdEvents.client.resetStore()
                        userLogin({
                            variables: {
                                UserLoginInput
                            }
                        })
                        
                    }}>
                        {false && <Message negative>
                            <Message.Header>Ha ocurrido un error</Message.Header>
                            {error && <p>{error?.message}</p>}
                            {recoverPasswdEvents.error && <p>{recoverPasswdEvents.error?.message}</p>}
                        </Message>}

                        {messageLogin.message.length > 0 && <Message positive={messageLogin.type === 'positive'} negative={messageLogin.type === 'negative'}>
                            <Message.Header>{messageLogin.type === 'positive' ? 'Solicitud de Recuperación' : 'Ha ocurrido un error'}</Message.Header>
                            <p>{messageLogin.message}</p>
                        </Message>}
                        <Form.Field>
                            <Form.Input
                                placeholder='Correo electrónico institucional'
                                name='mail'
                                value={UserLoginInput.mail}
                                onChange={updateChange}
                                label='Correo electrónico'
                                />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                placeholder='Contraseña propia'
                                name='password'
                                value={UserLoginInput.password}
                                onChange={updateChange}
                                type='password'
                                label='Contraseña'
                                />
                        </Form.Field>
                        {/* <Form.Checkbox
                            label='Confiar en este dispositivo'
                            name='trust'
                            checked={UserLoginInput.trust}
                            onChange={updateChange}
                        /> */}
                        <div style={{
                            textAlign: 'center',
                            paddingTop: 16
                        }}>
                            <Button type='submit' primary size='large'>Iniciar sesión</Button>

                            <Form.Field style={{
                                color: '#2185d0',
                                cursor: 'pointer',
                                marginTop: 8
                            }} onClick={() => {
                                recoverPasswd()
                            }}>
                                <p>¿Olvidaste tu contraseña?</p>
                            </Form.Field>
                        </div>
                    </Form>
                    <Divider horizontal>&nbsp;</Divider>
                    <p style={{
                        textAlign: 'center',
                        fontSize: 16
                    }}>¿No tienes cuenta? <Link to='' onClick={() => {
                        setEnrollment({
                            enabled: true,
                            domain: '',
                            mail: ''
                        })
                    }} style={{
                        fontWeight: 'bold'
                    }}>Regístrate</Link></p>
                    
                    
                    
                </Grid.Column>
                <Grid.Column>
                    <div className="VideoWelcome"  onClick={() => {
                            showVideo(true)
                        }}>
                        <Icon name='play circle' />
                        <video playsInline loop className="videoWelcome" src='https://cdn.dcvevolucion.cl/capsules/IntroDCV.mp4' muted autoPlay />
                    </div>
                    <h3>{('Nos estamos transformando')}</h3>
                    <p>En el DCV estamos creando el futuro hoy, y lo estamos haciendo mediante nuestro proyecto “DCV Evolución”, incorporando las necesidades de nuestros clientes, el mercado y las nuevas tecnologías.</p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
}