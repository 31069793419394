import { gql } from '@apollo/client'

export const USER_LOGIN = gql`
mutation UserLogin ($UserLoginInput: UserLoginInput!) {
    UserLogin (input: $UserLoginInput) {
        success
        state
        text
        token
        GroupInfo{
            id
            internalName
            name
        }
    }
}
`

export const RECOVER_PASSWD = gql`
mutation UserRecoverPasswd ($UserRecoverPasswdInput: UserRecoverPasswdInput!) {
    UserRecoverPasswd (input: $UserRecoverPasswdInput) {
        success
    }
}
`

export const USER_LEARNING_ROUTES = gql`
query UserLearningRoutes {
    UserLearningRoutes {
      LearningRoute {
        id
        parentId
        name
        description
        icon
        sortOrder
        image
        slug
        Courses {
          id
          typeId
          learningRouteId
          sortOrder
          name
          description
          image
          slug
          href
          enabled
          times
          status {
            code
            name
          }
        }
      }
    }
} 
`

export const GET_ROUTES_LIST = gql`
query getRoutesList ($getRoutesListInput: getRoutesListInput!) {
    getRoutesList (input: $getRoutesListInput) {
      LearningRoute {
        id
        parentId
        name
        description
        icon
        sortOrder
        image
        slug
        Courses {
          id
          typeId
          learningRouteId
          sortOrder
          name
          description
          image
          slug
          href
          enabled
          times
          status {
            code
            name
          }
        }
      }
    }
}
`

export const GET_COURSE_DETAIL = gql`
query getCourseDetail ($CourseDetailInput: CourseDetailInput!) {
    getCourseDetail (input: $CourseDetailInput) {
      Course {
         id
          typeId
          learningRouteId
          sortOrder
          name
          description
          image
          slug
          href
          enabled
          times
          qualify
          status {
            code
            name
          }
      }
      LearningResources {
        id
        typeId
        name
        description
        extendedDescription
        timeline
        image
        configuration
      }
      LearningRoute{
        id
        name
      }
      RelatedCourses{
        name
        href
      }
    }
}
`

export const COURSE_QUALIFY = gql`
mutation courseQualify ($CourseQualifyInput: CourseQualifyInput!) {
    courseQualify (input: $CourseQualifyInput) {
        success
    }
}
`

export const USER_REGISTER = gql`
mutation UserRegister ($UserRegisterInput: UserRegisterInput!) {
    UserRegister (input: $UserRegisterInput) {
        success
    }
}`

export const USER_VALIDATE_ACCOUNT = gql`
mutation UserValidate ($UserValidateInput: UserValidateInput!) {
    UserValidate (input: $UserValidateInput) {
        success
        token
    }
}`

export const USER_CHECKDOMAIN = gql`
mutation CheckDomain ($CheckDomainInput: CheckDomainInput!) {
    CheckDomain (input: $CheckDomainInput) {
        success
        text
        state
    }
}`