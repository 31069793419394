import { Route, Redirect, useLocation } from 'react-router-dom'

import { connect, useSelector } from 'react-redux'
import useMobile from '../Hooks/useMobile'
// import AppFrame from '../Components/AppFrame'

const mapStateToProps = ({ token, isLogged }: any) => {
    return { token, isLogged }
}

const PrivateRoute = ({ component: Component, isLogged, ...rest}: any) => {
    const isMobile = useMobile()
    const { pathname } = useLocation()
    const { userInfo: { hasRoot } } = useSelector((state: any) => state)

    return (
        <Route {...rest} render={(props: any) => {
            const stateProps = {
                isLogged,
                isMobile,
                hasRoot
            }
            return (hasRoot ?
                    <Component {...props} {...stateProps} />
            : <Redirect to={`/courses?forbidden=${pathname}`} />
        )}} />
    )
}

export default connect(mapStateToProps)(PrivateRoute)