import React, { useContext, useEffect, useState } from 'react'

import {
    Grid,
    Input,
    Icon,
    Header,
    Button,
    Table,
    Popup,
    Pagination,
    Confirm
} from 'semantic-ui-react'

import './UserList.scss'

import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { logout } from '../../Store/Actions'
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client'
import LoaderContext from '../../Library/Context/LoaderContext'
import useMobile from '../../Hooks/useMobile'
import TopBar from '../../Components/TopBar'

import { RECOVER_PASSWD, USER_LEARNING_ROUTES } from '../connections'
import { AdminMenu } from './AdminMenu'

type UserDataType = {
    id: number
    firstName: string
    lastName: string
    mail: string
    enabled: number
    UserGroups?: any
}

function useQueryParams() {
    const query = new URLSearchParams(useLocation().search)

    return {
        page: Number(query.get('page') || 1),
        search: query.get('search') || undefined
    }
}

export default function PillList() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { isMobile } = useMobile()
    const { token, learningRoutes } = useSelector((state: any) => state)
    const { page = 1, search = '' } = useQueryParams();


    const [itemList, setItemList] = useState<any>([])
    const [query, setQuery] = useState<string>('')
    const [, setSearching] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<UserDataType>()
    const [form, setForm] = useState({
        search: ''
    })
    const [confirm, setConfirm] = useState<boolean>(false)
    
    const { setLoading } = useContext(LoaderContext)
    const { loading, data, refetch } = useQuery(USER_LEARNING_ROUTES, {
        onError: (error) => {
            // @todo: Error sin permisos.
            if (error.message === 'Unauthenticated') {
                dispatch(logout(null))
                return history.push('/', {
                    error
                })
            }
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })
    const [doRecover, recoverPasswdEvents] = useMutation(RECOVER_PASSWD)

    useEffect(() => {
        refetch()
    }, [page, search, refetch])

    useEffect(() => {
        setLoading(loading || recoverPasswdEvents.loading)
    }, [loading, recoverPasswdEvents.loading, setLoading])

    useEffect(() => {
        if(recoverPasswdEvents.data?.UserRecoverPasswd?.success) {
            alert(`Contraseña reiniciada con éxito para ${selectedUser?.mail}, ha sido enviado al correo "pruebasmercado@dcv.cl".`)
            setSelectedUser(undefined)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recoverPasswdEvents.data])

    useEffect(() => {
        const searchQuery = query.trim()
        const params = new URLSearchParams()
        if (searchQuery) {
            params.append("search", searchQuery)
        } else {
            params.delete("search")
        }
        history.push({search: params.toString()})
    }, [query, history])

    useEffect(() => {
        if (!isMobile && !loading && data?.UserLearningRoutes?.LearningRoute) {
            let menuWidth = [].slice.call(document.getElementsByClassName('MenuItemElement')).reduce((acc, lr: any) => {
                /* console.log({
                    word: lr.innerHTML,
                    length: lr.innerHTML.length + 0,
                    width: lr.offsetWidth,
                    letterWidth: lr.offsetWidth / lr.innerHTML.length
                }) */
                return acc + lr.offsetWidth
            }, 0)
            if (menuWidth > window.innerWidth) {
                // ¿Cuantos caben?
                // Promedio por letra: 12px.
            }
            /* for (const elementt of ) {
                menuWidth += elementt.offsetWidth
            } */
        }
    }, [isMobile, data, loading])

    useEffect(() => {
        if (data?.UserLearningRoutes?.LearningRoute){
            const list = data?.UserLearningRoutes?.LearningRoute.reduce((acc: any, item: any) => {
                for (const course of item.Courses) {
                    acc.push(course)
                }
                return acc
            }, [])
            setItemList(list)
        }
    }, [data])

    const updateForm = (e: any, { name, value }: any) => {
        const update = {...form}
        // @ts-ignore
        update[name] = value
        setForm(update)
    }

    return <Grid className="ViewCourses">
        <TopBar
            
            centerColumn={<Input icon placeholder='Escribe aquí para buscar un curso...' fluid className="SearchBar" value={form.search} name="search" onChange={updateForm}>
            <input onKeyPress={(event) => {
                if (event.key === 'Enter') {
                    setQuery(form.search)
                    setSearching(true)
                }
            }} />
            <Icon name='search' />
        </Input>} />
        <Confirm
          open={confirm}
          content={`¿Estás seguro de reiniciar la contraseña para ${selectedUser?.mail}`}
          header={`Reinicio de contraseña para ${selectedUser?.lastName}, ${selectedUser?.firstName}`}
          onCancel={() => {
            setConfirm(false)
            setSelectedUser(undefined)
          }}
          onConfirm={() => {
            setConfirm(false)
            doRecover({
                variables: {
                    UserRecoverPasswdInput: {
                        mail: selectedUser?.mail
                    }
                }
            })
          }}
        />
        <Grid.Row columns="equal">
            <AdminMenu />
            
            <Grid.Column>
                <Table celled collapsing style={{ width: '100%' }}>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Cápsula</Table.HeaderCell>
                        <Table.HeaderCell>Categoría</Table.HeaderCell>
                        <Table.HeaderCell>Herramientas</Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {itemList?.map((item: any) => {
                            
                        return <Table.Row>
                            <Table.Cell>
                            <Header as='h4' image>
                                {/* <Label color='red' floating circular empty /> */}
                                
                                <Header.Content>
                                {item.name}
                                <Header.Subheader>
                                    <Popup
                                        trigger={<Icon name='info circle' />}
                                        content={`Identificador único: ${item.id}`}
                                        inverted
                                        size='tiny'
                                        />{item.description}
                                </Header.Subheader>
                                </Header.Content>
                            </Header>
                            </Table.Cell>
                            <Table.Cell>{learningRoutes.find((a: any) => a.id === item.learningRouteId)?.name}</Table.Cell>
                            <Table.Cell>
                                <Button.Group basic size='tiny'>
                                    <Button labelPosition='left' icon='edit' content='Editar' onClick={() => {
                                        history.push(`/admin/courses/edit/${item.learningRouteId}/${item.id}`)
                                    }} />
                                    {/* <Button labelPosition='left' icon='right chevron' content='Forward' /> */}
                                </Button.Group>
                            </Table.Cell>
                        </Table.Row>})}
                    </Table.Body>
                </Table>
            </Grid.Column>
        </Grid.Row>
        {data?.adminGetUserList?.pagination?.last > 1&& <Grid.Row>
            <Pagination
                defaultActivePage={data?.adminGetUserList?.pagination.current}
                totalPages={data?.adminGetUserList?.pagination.last}
                ellipsisItem={data?.adminGetUserList?.pagination.last > 5 ? undefined : null}
                onPageChange={(event, pagedata) => {
                    // console.log(pagedata.activePage)
                    setLoading(true)
                    history.push({
                        pathname: '/admin/courses/list',
                        search: `?page=${pagedata.activePage}&search=${search}`
                    })
                }}
                />
        </Grid.Row>}
    </Grid>
}